<template>
  <div class="app-context">
    <div class="context flex withSide" style="margin-top: 130px">
      <div class="hospital-list" style="height: 100%">
        <div class="notification">
          <div class="patient-count">
            <span>신청서 관리</span>
            <h1
              v-if="currentTab === 1"
              style="line-height: 30px; color: #146f83"
            >
              ||
            </h1>
          </div>
        </div>
        <div style="border: 1px solid #dfe1e3"></div>
        <div style="height: 20px"></div>
        <div style="width: 100%; height: 100px">
          <div style="height: 20px"></div>
          <tr style="width: 100%; height: 40px; display: flex">
            <!--<td style="width : 20%; background-color : #4682B4;  text-align: center; color : #ffffff; line-height : 35px">
                    <span>기간별</span>
                </td>-->
            <td style="width: 100%; line-height: 35px">
              <div style="width: 100%; display: flex; text-align: center">
                <span style="width: 2%; display: flex"></span>
                <span style="width: 38%; display: flex">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        ref="dateInputFirst"
                        type="text"
                        style="
                          width: 40%;
                          text-align: center;
                          border: 1px solid #000;
                        "
                        v-model="conditional_date_array[0]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click="pickerOffset(0)"
                      />
                    </template>
                    <v-date-picker
                      calss="picker1"
                      color="#146F83"
                      header-color="#146F83"
                      v-model="picker_first"
                      @change="click_picker(0)"
                      locale="kr"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                  <span style="width: 20%; font-size: 30px">~</span>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <input
                        ref="dateInputSecond"
                        type="text"
                        style="
                          width: 40%;
                          text-align: center;
                          border: 1px solid #000;
                        "
                        v-model="conditional_date_array[1]"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        @click="pickerOffset(1)"
                      />
                    </template>
                    <v-date-picker
                      calss="picker1"
                      color="#146F83"
                      header-color="#146F83"
                      v-model="picker_second"
                      @change="click_picker(1)"
                      locale="kr"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </span>
                <span
                  style="width: 10%; cursor: pointer"
                  @click="conditional_date('month')"
                  >당월</span
                >
                <span
                  style="width: 10%; cursor: pointer"
                  @click="conditional_date('month_back')"
                  >1달 전</span
                >
                <span
                  style="width: 10%; cursor: pointer"
                  @click="conditional_date('3month_back')"
                  >3달 전</span
                >
                <span
                  style="width: 10%; cursor: pointer"
                  @click="conditional_date('all')"
                  >전체</span
                >
                <div
                  style="
                    background-color: #146f83;
                    width: 130px;
                    height: 40px;
                    color: #ffffff;
                    font-size: 24px;
                    cursor: pointer;
                    position: relative;
                    left: 2%;
                  "
                >
                  <p
                    @click="clickSearch()"
                    style="
                      width: 100%;
                      height: 100%;
                      position: relative;
                      text-align: center;
                      top: 5%;
                    "
                  >
                    검색
                  </p>
                </div>
              </div>
            </td>
          </tr>
        </div>

        <div style="border: 1px solid #dfe1e3"></div>
        <div class="list-complex">
          <div class="list-wrap" v-if="list.length > 0 && !isChildLoading">
            <div style="height: 40px">
              <tr>
                <td class="col-md-1" style="width: 50px" />
                <td class="col-md-2">
                  <div style="width: 150px">신청서 ID</div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">신청날짜</div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">신청자 ID</div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">진행 상태</div>
                </td>
                <td class="col-md-2"><div style="width: 150px"></div></td>
                <td class="col-md-1" />
              </tr>
            </div>
          </div>
        </div>
        <div style="border: 1px solid #dfe1e3"></div>
        <div class="list-complex">
          <div class="list-wrap" v-if="list.length > 0 && !isChildLoading">
            <div
              class="list"
              v-for="(listItem, index) in list"
              :key="index + 'A'"
              @click="selectList(listItem, index)"
            >
              <tr>
                <td class="col-md-1" style="width: 50px" />
                <td class="col-md-2">
                  <div style="width: 150px">
                    {{ listItem.aplyId }}
                  </div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">
                    {{ listItem.aplyDt }}
                  </div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">
                    {{ listItem.aplyDrId }}
                  </div>
                </td>
                <td class="col-md-2">
                  <div style="width: 150px">
                    <span v-if="listItem.prgsStatCd == '00'">신청서작성</span>
                    <span v-if="listItem.prgsStatCd == '01'">주문완료</span>
                    <span v-if="listItem.prgsStatCd == '02'">취소불가</span>
                    <span v-if="listItem.prgsStatCd == '03'">응답대기</span>
                    <span v-if="listItem.prgsStatCd == '04'">전달대기</span>
                    <span v-if="listItem.prgsStatCd == '05'">전달완료</span>
                    <span v-if="listItem.prgsStatCd == '10'">구매확정</span>
                    <span v-if="listItem.prgsStatCd == '20'">결제취소</span>
                    <span v-if="listItem.prgsStatCd == '30'">결제중단</span>
                    <span v-if="listItem.prgsStatCd == '99'">결제실패</span>
                  </div>
                </td>
                <td class="col-md-2" />
                <td class="col-md-1" />
              </tr>
            </div>

            <div class="pagination-wrap" style="left: 0px">
              <p
                class="navigation-act prev"
                v-if="listStatus.first > 1"
                @click="navigatorAct('prev')"
              >
                <v-icon
                  large
                  color="teal darken-2"
                  style="left: 40px; top: -10px"
                >
                  mdi-chevron-left
                </v-icon>
              </p>
              <ul id="pagination">
                <li
                  v-for="(
                    pagination, index
                  ) in listStatus.currentPaginationList"
                  :key="index + 'j'"
                  @click="loadList(pagination, index)"
                >
                  <p style="top: 7px">{{ pagination }}</p>
                </li>
              </ul>
              <p
                class="navigation-act next"
                v-if="
                  listStatus.next > 5 && listStatus.last < listStatus.totalPage
                "
                @click="navigatorAct('next')"
              >
                <v-icon
                  large
                  color="teal darken-2"
                  style="left: -10px; top: -10px"
                >
                  mdi-chevron-right
                </v-icon>
              </p>
            </div>
          </div>
          <div class="notification-list none-border" v-else>
            <div class="item">
              <div class="logo-wrap">
                <img src="@/assets/images/logo_unplugged.svg" />
              </div>
              <h4>조회결과 없음</h4>
            </div>
          </div>
          <div class="spinner" v-if="isChildLoading">
            <transition name="slide-fade" mode="in-out" v-if="isChildLoading">
              <beat-loader
                :loading="isChildLoading"
                :color="loader.color"
                :size="loader.size"
              ></beat-loader>
            </transition>
          </div>
        </div>
      </div>
      <button id="focusControl"></button>
    </div>
    <!-- <app-footer></app-footer> -->
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/appHeader/AppHeaderTest";
import DefaultPopup from "@/components/modal/DefaultPopup";
import AppFooter from "@/components/AppFooter";
import SideMenu from "@/components/sidemenu/SideMenu";
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import BeatLoader from "vue-spinner/src/BeatLoader";

export default {
  components: {
    DefaultPopup,
    AppHeader,
    AppFooter,
    SideMenu,
    BeatLoader,
  },
  data() {
    return {
      type: "전체",
      typeToLabel: {
        전체: "전체",
        "회신 완료": "회신 완료",
        "내원 완료": "내원 완료",
        "되의뢰 완료": "되의뢰 완료",
      },
      headerOption: {
        navOrder: 0,
        isMain: true,
      },
      popupSet: {},
      sideSet: {
        menuName: "payment",
        sideTitle: "소개 디자인",
        activeOrder: 0,
        isWritedSearch: null,
        isWritedPr: null,
      },
      currentTab: "",
      searchOpt: {
        receiveOrder: 0, // default 옵션,
        recordsPerPage: "", // 한번에 불러올 갯수
      },
      listStatus: {
        totalDataCnt: null, // 조회하여 가져온 총 데이터의 수
        dataOnPage: 10, // 한 페이지에 내려줄 데이터의 수
        pageCount: 5, // 한 페이지에 1~5까지 보여준다.
        totalPage: null, // 페이지네이션에 출력한 총 페이지의 수
        currentPage: 1, // 현재 페이지
        tempCount: null,
        prev: null,
        next: null,
        first: null,
        last: null,
        currentPaginationList: [],
        sendCount: null,
        cancelCount: null,
        /**
         * totalDataCnt: 22
         * currentPage: 1
         * totalPage: 3
         * pageGroup: 1
         * last: 3
         * first: -1
         * next: 4
         * prev: -2
         */
      },
      loader: {
        loading: false,
        color: "#146f83",
        size: "18px",
      },
      list: [],
      list_check: [],
      setCount: false,
      conditional_date_array: [
        this.$moment().add(-1, "months").format("YYYYMMDD"),
        this.$moment().format("YYYYMMDD"),
      ],
      conditional_condition: 0,
      picker_first: "",
      picker_second: "",
      menu1: false,
      menu2: false,
      picker_first_offset: [0, 0],
      picker_second_offset: [0, 0],
      checkCount: 0,
      refund_holder: "",
      refund_bank: "",
      refund_account: "",
    };
  },
  created() {
    window.scrollTo(0, 0);
    // console.log("this");
    // console.log(history.pushState);
    if (history.pushState !== undefined) {
      history.pushState({}, "", location.href);
    }
  },
  mounted() {
    if (this.GET_LIST_ORDER !== null) {
      this.sideMenuCall(this.GET_LIST_ORDER);
    }

    //console.log("==============================================");
    this.reload();
    //console.log();

    document.querySelector(".designManageApply").classList.add("active"); //사이드메뉴 불키기
  },
  updated() {},
  computed: {
    ...mapGetters("join", ["GET_BASIC"]),
    ...mapGetters("util", ["GET_EXEL_DATA"]),
    ...mapState("basic", ["checkPopup", "isChildLoading"]),
    ...mapGetters("referList", [
      "GET_SEND_LIST",
      "GET_RECEIVE_LIST",
      "GET_LIST_ORDER",
      "GET_RES_DOCTOR_DATA",
      "GET_REQ_DOCTOR_DATA",
      "GET_REFER_DETAIL",
    ]),
    ...mapGetters("request", [
      "GET_REFER_ID",
      "GET_REPLY_STATUS",
      "GET_APPLY_LIST",
      "GET_TOKKEN_IMPORT",
      "GET_RESP",
    ]),
    daySet() {
      return (day) => {
        //console.log("day", day);
        if (day !== undefined) {
          let dano = "";
          dano += day.substr(0, 4);
          dano += "/";
          dano += day.substr(4, 2);
          dano += "/";
          dano += day.substr(6, 2);
          return dano;
        }
      };
    },
    getGender() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("gender-Value", value);
          const genderCount = value.substr(7, 8);
          if (genderCount === "2" || genderCount === "4") {
            return "여";
          } else if (genderCount === "1" || genderCount === "3") {
            return "남";
          } else if (
            genderCount === "5" ||
            genderCount === "6" ||
            genderCount === "7" ||
            genderCount === "8"
          ) {
            return "외국인";
          }
        } else {
          return null;
        }
      };
    },
    getAge() {
      return (value) => {
        if (value !== null && value !== "" && value !== undefined) {
          //console.log("Age-value", value);
          let currentAge;
          const ageCount = value.substr(0, 2);
          //console.log("Age-count", ageCount);
          const now = new Date().getFullYear();
          //console.log("Age-now", now);
          const item = String(now).substr(2, 4);
          //console.log("calcu-now", item);
          if (Number(ageCount) >= Number(item)) {
            const plusSu = 100 - Number(ageCount);
            //console.log("plusSu", plusSu);
            currentAge = Number(item) + Number(plusSu);
          } else {
            currentAge = Number(item) - Number(ageCount);
          }
          return currentAge;
        } else {
          return null;
        }
      };
    },
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP", "SET_CHILD_LOADER"]),
    ...mapMutations("request", ["SET_REFER_ID", "SET_REPLY_STATUS"]),
    ...mapActions("referList", [
      "BRING_SEND_LIST",
      "RECEIVE_SEND_LIST",
      "RECEIVE_SEND_LIST_ALL",
    ]),
    ...mapActions("request", [
      "BRING_APPLY_LIST",
      "BRING_TOKKEN_IMPORT",
      "CANCLE_ORDER",
    ]),
    goHome() {
      this.$router.push("/").catch(() => {});
    },
    ...mapMutations("referList", ["SET_REFER_DETAIL"]),
    async selectList(listItem, index) {
      // console.log("clickList :", listItem);
      if (listItem.prgsStatCd === "10") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "이미 구매 종결된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "20") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "30") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제진행중 취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else if (listItem.prgsStatCd === "99") {
        this.SET_POPUP(true);
        this.popupSet.title = "확인창";
        this.popupSet.content =
          "결제 오류로 인해 취소된 신청서입니다. 새 신청서를 작성해 주세요.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      } else {
        this.$router.push("apply_O").catch(() => {});
      }
    },
    pickerOffset(picker) {
      if (picker === 0) {
        // console.log(this.$refs.dateInputFirst);
        const clientRect = this.$refs.dateInputFirst.getBoundingClientRect();
        // console.log(clientRect.top);
        // console.log(clientRect.left);

        this.picker_first_offset = [clientRect.left, clientRect.top];

        // console.log(this.$refs.menu1.styles);
      } else if (picker === 1) {
        // console.log(this.$refs.dateInputSecond);
        const clientRect = this.$refs.dateInputSecond.getBoundingClientRect();
        // console.log(clientRect.top);
        // console.log(clientRect.left);
        this.picker_second_offset = [clientRect.left, clientRect.top];
      }
    },
    async clickSearch() {
      // console.log(this.conditional_date_array);
      if (this.type === "전체") {
        this.conditional_condition = 0;
      } else if (this.type === "회신 완료") {
        this.conditional_condition = 1;
      } else if (this.type === "내원 완료") {
        this.conditional_condition = 2;
      } else if (this.type === "되의뢰 완료") {
        this.conditional_condition = 3;
      }

      // console.log(this.conditional_condition);
      // console.log(
      //   this.$moment(this.conditional_date_array[1]).isAfter(
      //     this.conditional_date_array[0]
      //   )
      // );
      if (
        this.conditional_date_array[0] === "전체" &&
        this.conditional_date_array[1] === "전체"
      ) {
        this.conditional_date_array[0] = null;
        this.conditional_date_array[1] = null;
        this.reload();
      } else if (
        this.conditional_date_array[0] !== undefined &&
        this.conditional_date_array[0] !== null &&
        this.conditional_date_array[1] !== undefined &&
        this.conditional_date_array[1] !== null
      ) {
        if (
          this.$moment(this.conditional_date_array[1]).isAfter(
            this.conditional_date_array[0]
          )
        ) {
          this.reload();
        } else {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content =
            "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content =
          "검색조건이 알맞지 않습니다. 다시한번 확인해 주세요";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    click_picker(str) {
      if (str === 0) {
        // console.log(this.picker_first);
        const [year, month, day] = this.picker_first.split("-");
        // console.log(`${year}${month}${day}`);
        this.conditional_date_array[0] = `${year}${month}${day}`;
      } else if (str === 1) {
        // console.log(this.picker_second);
        const [year, month, day] = this.picker_second.split("-");
        // console.log(`${year}${month}${day}`);
        this.conditional_date_array[1] = `${year}${month}${day}`;
      }
    },
    async conditional_date(type) {
      if (type === "all") {
        this.conditional_date_array = ["전체", "전체"];
      } else if (type === "month") {
        this.conditional_date_array = [
          this.$moment().subtract(0, "M").format("YYYYMM") + "01",
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(1, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      } else if (type === "3month_back") {
        this.conditional_date_array = [
          this.$moment().subtract(3, "M").format("YYYYMMDD"),
          this.$moment().format("YYYYMMDD"),
        ];
      }
    },
    initListStatus() {
      this.listStatus.totalDataCnt = null;
      this.listStatus.dataOnPage = 10;
      this.listStatus.pageCount = 5;
      this.listStatus.totalPage = null;
      this.listStatus.currentPage = 1;
      this.listStatus.prev = null;
      this.listStatus.next = null;
      this.listStatus.first = null;
      this.listStatus.last = null;
      this.currentPaginationList = [];
    },
    initPaginationEffect() {
      for (
        let k = 0;
        k < document.querySelector("#pagination").childElementCount;
        k++
      ) {
        // console.log(
        //   "Ds",
        //   document.querySelector("#pagination").childNodes[k].childNodes[0]
        //     .textContent
        // );
        if (
          document
            .querySelector("#pagination")
            .childNodes[k].classList.contains("active")
        ) {
          document
            .querySelector("#pagination")
            .childNodes[k].classList.remove("active");
        }
      }
    },
    async loadList(currentPage, order) {
      this.list = [];
      this.SET_CHILD_LOADER(true);
      this.initPaginationEffect();
      // console.log(currentPage);
      // console.log(order);
      // console.log(this.listStatus.last);
      document
        .querySelector("#pagination")
        .childNodes[0].classList.add("active");
      /*if (this.listStatus.totalCount >= 1) {
        if (order > this.listStatus.last) {
          order = this.listStatus.last - 1;
        }
        document
          .querySelector("#pagination")
          .childNodes[order].classList.add("active");
      }*/
      const searchOpt = {
        drId: localStorage.getItem("drId"),
        recordsPerPage: this.listStatus.dataOnPage,
        pickDateFirst: this.conditional_date_array[0],
        pickDateSecond: this.conditional_date_array[1],
        pageNo: currentPage,
      };

      if (this.searchOpt.receiveOrder === 0) {
        try {
          await this.BRING_APPLY_LIST(searchOpt).then(() => {
            // console.log(this.GET_APPLY_LIST);
            this.listStatus.totalCount = this.GET_APPLY_LIST.param.totalCount;
            this.listStatus.totalPage = this.GET_APPLY_LIST.param.totalPage;
            this.listStatus.first = this.GET_APPLY_LIST.param.first;
            this.listStatus.last = this.GET_APPLY_LIST.param.last;
            this.listStatus.prev = this.GET_APPLY_LIST.param.prev;
            this.listStatus.next = this.GET_APPLY_LIST.param.next;
            this.listStatus.currentPage = this.GET_APPLY_LIST.param.pageNo;
            let tempList = JSON.parse(JSON.stringify(this.GET_APPLY_LIST.list));
            setTimeout(() => {
              this.SET_CHILD_LOADER(false);
              this.list = tempList;
              this.preParePagination();
            }, 800);
          });
        } catch (error) {
          console.log("error :", error);
          if (error.data.status !== 5000 && error.data.status !== 5002) {
            this.SET_POPUP(true);
            this.popupSet.title = "서버요청 실패";
            this.popupSet.content = " 리스트를 불러올 수 없습니다.";
            this.popupSet.popType = "warn";
            this.popupSet.cancelBtnText = "확인";
          }
        }
      }
    },
    preParePagination() {
      /**
      this.listStatus.totalDataCnt = 122;
       */
      /** api로 list를 불러온 과정 생략 */
      // console.log("==========================================================");
      // console.log(this.listStatus);
      const totalDataCnt = this.listStatus.totalDataCnt;
      const currentPage = this.listStatus.currentPage;
      // console.log("totalDataCnt", totalDataCnt);
      // console.log("currentPage", currentPage);
      const dataOnPage = this.listStatus.dataOnPage;
      const pageCount = this.listStatus.pageCount;
      const totalPage = Math.ceil(totalDataCnt / dataOnPage);
      const pageGroup = Math.ceil(currentPage / pageCount);
      // console.log("totalPage", totalPage);
      // console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      if (last > totalPage) {
        last = totalPage;
      }
      let first = last - (pageCount - 1);
      const next = last + 1;
      const prev = first - 1;
      if (totalPage < 1) {
        first = last;
      }
      // console.log("last : " + last);
      // console.log("first : " + first);
      // console.log("next :" + next);
      // console.log("prev :" + prev);
      this.listStatus.totalPage = totalPage;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.listStatus.first = first;
      this.listStatus.last = last;
      this.calculatedPaginationList(first, last);
      /**
       * totalDataCnt: 22
       * currentPage: 1
       * totalPage: 3
       * pageGroup: 1
       * last: 3
       * first: -1
       * next: 4
       * prev: -2
       */
      /** [next]
       *  first -> pageGroup  * dataOnPage + 1
       *  last  -> ((pageGroup + 1) * dataOnPage) - 1
       *  [prev]
       *  first -> ((pageGroup - 2) * dataOnPage) + 1
       *  last  -> ((pageGroup - 1) * dataOnpage) - 1
       *
       */
    },
    calculatedPaginationList(first, last, type) {
      const paginationListArray = [];
      // console.log(first);
      // console.log(last);
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      for (let i = 0; i <= 5; i++) {
        if (last % 5 !== 0) {
          if (paginationListArray[0] % 5 !== 1) {
            paginationListArray.shift();
          } else {
            break;
          }
        }
      }

      this.listStatus.currentPaginationList = paginationListArray;
      if (type === "prev") {
        this.$nextTick((_) => {
          if (this.listStatus.last % 5 !== 0) {
            let num = this.listStatus.last % 5;
            this.listStatus.last = this.listStatus.last + (5 - num);
            this.loadList(this.listStatus.last, 4);
          } else {
            this.loadList(this.listStatus.last, 4);
          }
          for (
            let k = 0;
            k < document.querySelector("#pagination").childElementCount;
            k++
          ) {
            // console.log(
            //   "재료",
            //   document.querySelector("#pagination").childNodes[k].childNodes[0]
            //     .textContent
            // );
            if (
              Number(
                document.querySelector("#pagination").childNodes[k]
                  .childNodes[0].textContent
              ) === Number(this.listStatus.last)
            ) {
              document
                .querySelector("#pagination")
                .childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.loadList(this.listStatus.currentPage, 0);
        document
          .querySelector("#pagination")
          .childNodes[0].classList.add("active");
      } else {
        if (this.list.length > 0) {
          this.$nextTick((_) => {
            const target =
              document.querySelectorAll(".tab-wrapper")[this.currentTab];
            if (target !== undefined) {
              target
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            // console.log(document.querySelector("#pagination"));
            // console.log((this.listStatus.currentPage % 5) - 1);
            if (this.listStatus.currentPage > 5) {
              if (this.listStatus.currentPage % 5 === 0) {
                document
                  .querySelector("#pagination")
                  .childNodes[4].classList.add("active");
              } else {
                document
                  .querySelector("#pagination")
                  .childNodes[
                    (this.listStatus.currentPage % 5) - 1
                  ].classList.add("active");
              }
            } else {
              document
                .querySelector("#pagination")
                .childNodes[this.listStatus.currentPage - 1].classList.add(
                  "active"
                );
            }
            //강제 active 삽입
          });
        }
      }
    },
    navigatorAct(type) {
      let first = this.listStatus.first;
      let last = this.listStatus.last;
      const totalPage = this.listStatus.totalPage;
      const paginationListArray = [];
      let next = this.listStatus.next;
      let prev = this.listStatus.prev;
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.listStatus.currentPaginationList = paginationListArray;
      this.initPaginationEffect();
      /**
       * first next last
       * 보정 가정
       * */
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 5;
        prev = first - 1;
        /**
         * 11    15
         * 6     10
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 5;
        prev = first - 1;
        if (totalPage > last + 5) {
          last = last + 5;
          next = last + 1;
        } else {
          last = totalPage;
          next = last;
        }
        /**
         * 6    10
         * 11   15
         * currentPage = first
         * totalCount = 13
         */
        this.listStatus.currentPage = first;
      }

      this.listStatus.first = first;
      this.listStatus.last = last;
      this.listStatus.prev = prev;
      this.listStatus.next = next;
      this.calculatedPaginationList(first, last, type);
    },
    sideMenuCall(order) {
      this.$nextTick((_) => {
        //document.querySelector(".tabs__header").childNodes[order].click();
      });
    },

    async reload() {
      localStorage.setItem("currentTab", 0);
      this.initListStatus();
      this.list = [];
      const list = [];
      this.listStatus.totalDataCnt = 0;
      this.SET_CHILD_LOADER(true);

      const searchOpt = {
        drId: localStorage.getItem("drId"),
        pageNo: 1,
        recordsPerPage: this.listStatus.dataOnPage,
        pickDateFirst: this.conditional_date_array[0],
        pickDateSecond: this.conditional_date_array[1],
      };

      this.searchOpt.receiveOrder = 0;
      try {
        await this.BRING_APPLY_LIST(searchOpt).then(() => {
          // console.log(this.GET_APPLY_LIST);
          this.listStatus.totalDataCnt = this.GET_APPLY_LIST.param.totalCount;
          for (let i = 0; i < this.GET_APPLY_LIST.list.length; i++) {
            list.push(this.GET_APPLY_LIST.list[i]);
          }
        });
      } catch (error) {
        console.log("error :", error);
        if (error.data.status !== 5000 && error.data.status !== 5002) {
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 리스트를 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      }
      this.list = list;
      setTimeout(() => {
        this.SET_CHILD_LOADER(false);
        this.preParePagination();
      }, 800);
    },
  },
  destroyed() {
    this.setCount = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/request";
.picker1 {
  position: absolute;
}

input, textarea {
  -moz-user-select: auto;

  -webkit-user-select: auto;

  -ms-user-select: auto;

  user-select: auto;
}
</style>
